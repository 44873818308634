<template>
  <vx-card class="company-page main-box top-zero-radius md:w-1/2" no-shadow>
    <vs-row
      vs-type="flex"
      vs-justify="space-around">

      <!-- Img Row -->
      <div class="flex flex-wrap items-center mb-base">
        <vs-row
          vs-type="flex"
          vs-justify="space-around">
          <vs-avatar
            :src="clubInfo.logo ?`https://irtns.ir/api/${clubInfo.logo}` : 'https://s16.picofile.com/file/8421955526/USERAL_logo.png'"
            size="150px"
            class="mr-4 mb-4"/>
        </vs-row>

        <vs-row
          vs-type="flex"
          vs-justify="center">
          <p class="w-4/5 text-xs text-center mt-2">{{ $t('setting.General.logo.rule') }}</p>
        </vs-row>
      </div>
      <vs-divider class="my-5"></vs-divider>
    </vs-row>

    <vs-row>

      <vs-col class="w-full md:w-1/3 d-inline-flex">
        <p class="h5 text-primary">اطلاعات عمومی باشگاه</p>
        <span class="text-justify text-xs">در این باکس اطلاعات عمومی باشگاه نمایش داده می شود.</span>
      </vs-col>

      <vs-col class="w-full md:w-2/3 d-inline-flex">

        <!-- club name input -->
        <custom-validate-input :classes="{'w-full': true, 'mb-base': true}"
                               align="right"
                               disabled
                               :label-placeholder="$t('setting.General.club.name')"
                               v-model="clubInfo.name"/>

        <custom-phone-number-input :classes="{'w-full': true, 'mb-base': true}"
                                   align="right"
                                   disabled
                                   :label-placeholder="$t('setting.General.club.telSupport')"
                                   v-model="clubInfo.telSupport"/>

        <custom-phone-number-input :classes="{'w-full': true, 'mb-base': true}"
                                   align="right"
                                   disabled
                                   :label-placeholder="$t('setting.General.club.whatsAppSupport')"
                                   v-model="clubInfo.whatsAppSupport"/>
      </vs-col>

      <vs-divider class="my-5"></vs-divider>
    </vs-row>

    <vs-row>

      <vs-col class="w-full md:w-1/3 d-inline-flex">
        <p class="h5 text-primary">اطلاعات آدرس باشگاه</p>
        <span class="text-justify text-xs">در این باکس اطلاعات آدرس شرکت نمایش داده می شود.</span>
      </vs-col>

      <vs-col class="w-full md:w-2/3 d-inline-flex">
        <div class="address-state" v-if="provinces.length && cities">
          <div class="address-province">
            <custom-select label="استان"
                           :classes="['w-full', 'my-3']"
                           styles="lineHeight: 35px;"
                           :options="provinces"
                           :default="clubInfo.province"
                           @suggest:selected="refreshCity"
                           :danger="clubInfo.province.label === '-'"
                           v-model="user.province"
                           searchable/>
          </div>

          <div class="address-city">
            <!--                <span class="label" :class="{'text-danger': user.province.label === '-'}">-->
            <!--                    شهر-->
            <!--                </span>-->
            <custom-select :options="cities[clubInfo.province.value] || []"
                           :classes="['w-full', 'my-3']"
                           styles="lineHeight: 35px;"
                           label="شهر"
                           :default="clubInfo.city"
                           :disabled="clubInfo.province.value === ''"
                           :danger="clubInfo.city.label === '-'"
                           v-model="clubInfo.city"
                           searchable/>
          </div>
        </div>

        <CustomValidateTextarea label-placeholder="آدرس"
                                align="right"
                                :is-empty="true"
                                :regex="addressRegex"
                                :classes="{'w-full': true, 'my-3': true}"
                                v-model="clubInfo.address"/>
      </vs-col>

      <vs-divider class="my-5"/>
    </vs-row>

<!--    <vs-row>-->

<!--      <vs-col class="w-full md:w-1/3 d-inline-flex">-->
<!--        <p class="h5 text-primary">اطلاعات مالی</p>-->
<!--        <span class="text-justify text-xs">در این باکس اطلاعات مالی شرکت نمایش داده می شود.</span>-->
<!--      </vs-col>-->

<!--      <vs-col class="w-full md:w-2/3 d-inline-flex">-->

<!--        &lt;!&ndash; club begin financial year &ndash;&gt;-->
<!--        <vs-input class="w-full mb-base"-->
<!--                  id="selectBeginDate"-->
<!--                  label-placeholder="شروع سال مالی"-->
<!--                  v-model="clubInfo.financial.from"-->
<!--                  disabled/>-->

<!--        &lt;!&ndash; club end financial year &ndash;&gt;-->
<!--        <vs-input class="w-full"-->
<!--                  id="selectEndDate"-->
<!--                  label-placeholder="انتهای سال مالی"-->
<!--                  v-model="clubInfo.financial.to"-->
<!--                  disabled/>-->
<!--      </vs-col>-->

<!--    </vs-row>-->
  </vx-card>
</template>

<script>
import {mapGetters} from 'vuex'
import {editSetting, uploadAvatar} from '@/http/requests/settings/setting'
import CustomValidateInput from "../../../../components/customInput/customValidateInput";
import CustomPhoneNumberInput from "../../../../components/customInput/customPhoneNumberInput";
import CustomValidateTextarea from "../../../../components/customInput/customValidateTextarea";
import CustomSelect from "@/components/customSelect/customSelect.vue";

export default {
  name: 'company',
  components: {CustomSelect, CustomValidateTextarea, CustomPhoneNumberInput, CustomValidateInput},
  metaInfo: {
    title: 'باشگاه'
  },
  data () {
    return {
      firstVisit: true,
      firstVisitTimer: 0,
      addressRegex: this.$validator('regex.company.address'),
      provinces: [],
      cities: {},
      clubInfo: {
        code: '',
        name: {
          value: 'باشگاه 1'
        },
        telSupport: {
          value: '09100000000',
          isValid: true
        },
        whatsAppSupport: {
          value: '09100000000',
          isValid: true
        },
        province: {
          id: '',
          label: ''
        },
        city: {
          id: '',
          label: ''
        },
        address: {
          value: '',
          isValid: true
        },
        managerName: '',
        logo: {},
        managerPhoneNumber: '',
        phoneNumber: '',
        financial: {
          from: '1399/09/19',
          to: '1400/09/19'
        }
      },
      actions: {
        toolbar: [
          {
            icon: 'EDIT',
            iconPack: 'useral',
            color: 'warning',
            type: 'link',
            id: {name: 'editCompany', params: {id: this.$route.params.id}}
          }
        ],
        leftToolbar: [
          {
            id: {name: 'Settings'},
            type: 'link',
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      club: 'setting/getClubInfo'
    }),
    activeUserInfo () {
      return this.$store.state.auth.user
    }
  },
  created () {
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'mx-auto md:w-1/2')
    })
  },
  mounted () {
    if (this.club.club_code) this.clubInfo.code = this.club.club_code

    if (this.club.club_name) this.clubInfo.name = this.club.club_name

    if (this.club.club_logo) this.clubInfo.logo = this.club.club_logo

    if (this.club.address) this.clubInfo.address = this.club.address

    if (this.club.phoneNumber) this.clubInfo.phoneNumber = this.club.phoneNumber
  },
  methods: {
    openChooseFileDialog () {
      document.getElementById('browseLogo').click()
    },
    avatarValidation (avatar) {
      if (avatar.size > (800 * 1026)) {
        this.$vs.notify({
          time: 4000,
          title: 'خطا',
          text: 'حجم فایل انتخاب شده بیش از حد مجاز است',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }

      let logoFormat = avatar.name.split('.')
      logoFormat = logoFormat[logoFormat.length - 1].toLowerCase()
      if (logoFormat !== 'png' && logoFormat !== 'jpg' && logoFormat !== 'jpeg') {
        this.$vs.notify({
          time: 4000,
          title: 'خطا',
          text: 'فرمت فایل انتخاب شده مجاز نیست',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }

      return true
    },
    uploadAvatar (event) {
      if (event.target.files && this.avatarValidation(event.target.files[0])) uploadAvatar(event.target.files[0]).then(response => {
        this.clubInfo.logo = response.data.path
        this.$vs.notify({
          time: 2400,
          title: 'پیغام',
          text: 'لوگو با موفقیت آپلود شد',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })
      }).catch(error => {
        this.$vs.notify({
          time: 2400,
          title: 'خطا',
          text: error.response.data.error || error.response.data.avatar,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    removeAvatar () {
      this.clubInfo.logo = ''
      this.$vs.notify({
        time: 2400,
        title: 'پیغام',
        text: 'لوگو با موفقیت حذف شد',
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'success'
      })
    },
    sendForm () {
      editSetting({
        club_code: this.clubInfo.code,
        club_name: this.clubInfo.name,
        club_logo: this.clubInfo.logo
      }).then(response => {
        this.$vs.notify({
          time: 2400,
          title: 'پیغام',
          text: 'اطلاعات فروشگاه با موفقیت بروز شد',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })

        this.$store.dispatch('removePageChanges')
      }).catch(error => {
        this.$vs.notify({
          time: 2400,
          title: 'خطا',
          text: error.response.data.message || error.response.data.error,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      this.$store.dispatch('setting/setClubInfo')
    }
  },
  watch: {
    clubInfo: {
      handler (val, oldVal) {
        clearTimeout(this.firstVisitTimer)
        this.firstVisitTimer = setTimeout(() => {
          this.firstVisit = false
        }, 500)

        if (!this.firstVisit) {
          this.$store.dispatch('setPageChanges')
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
